<template>
  <div class="report-data">
    <div class="report-data-item" v-for="(item, index) in list" :key="index">
      <div class="report-data-item__header">
        <div class="report-data-item__header__title">Report Date: {{ parseTime(item.reportDateStr, 'reg') }}</div>
        <el-button
          v-if="index < list.length - 1 || !isOldTower"
          class="report-data-item__heder__abtn"
          type="primary"
          size="mini"
          @click="handleDelete(item, index)">
          Delete
        </el-button>
        <el-button
          class="report-data-item__header__btn"
          type="success"
          size="mini"
          :loading="item.loading"
          @click="handleAdd(item)"
          v-if="!item.id"
        >
          Add
        </el-button>
      </div>
      <div class="report-data-item__upload">
        <div class="upload-item">
          <div class="upload-item__name">Inspection Report</div>
          <div class="upload-item__success" v-if="item.reportFilePath">
            <div class="file-box">
              <div class="file-left">
                <el-image class="file-icon" :src="FileIcon" />
                <span class="file-name">{{ item.reportFileRelativeName }}</span>
              </div>
              <el-image class="file-download" :src="DownloadIcon" @click="downloadFile(item.reportFilePath, item.reportFileRelativeName)" />
            </div>
            <el-image v-if="!item.id" class="file-delete" :src="Delete3" @click="delReport(index)" />
          </div>
          <file-upload v-else type="file" @success="(e) => postReport(e, index)"></file-upload>
        </div>
        <div class="upload-item" v-if="index < list.length - 1 || !isOldTower">
          <div class="upload-item__name">Defect list Excel</div>
          <div class="upload-item__success" v-if="item.defectListName">
            <div class="file-box">
              <div class="file-left">
                <el-image class="file-icon" :src="FileIcon" />
                <span class="file-name">{{ item.defectListName }}</span>
              </div>
              <el-image class="file-download" :src="DownloadIcon" @click="downloadFile(item.excelUrl, item.defectListName)" />
            </div>
            <el-image v-if="!item.id" class="file-delete" :src="Delete3" @click="delDefectListFile(index)" />
          </div>
          <file-upload v-else :api="`dev/tower/inputStaticExcel/${code}`" @success="(e) => postDefectListFile(e, index)"></file-upload>
        </div>
        <div class="upload-item" v-if="index < list.length - 1 || !isOldTower">
          <div class="upload-item__name">3D Tower Model</div>
          <div class="upload-item__success" v-if="item.towerModeUrl">
            <div class="file-box">
              <div class="file-left">
                <el-image class="file-icon" :src="FileIcon" />
                <span class="file-name">{{ item.towerModeName }}</span>
              </div>
              <el-image class="file-download" :src="DownloadIcon" @click="downloadFile(item.towerModeUrl, item.towerModeName)" />
            </div>
            <el-image v-if="!item.id" class="file-delete" :src="Delete3" @click="delTowerModel(index)" />
          </div>
          <file-upload v-else type="file" @success="(e) => postTowerModel(e, index)"></file-upload>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Delete from '../../../assets/icons/tower-manage/delete-2.png'
import FileIcon from '../../../assets/icons/tower-manage/file.png'
import DownloadIcon from '../../../assets/icons/tower-manage/download.png'
import Delete3 from '../../../assets/icons/tower-manage/delete-3.png'

import fileUpload from '@/components/upload/file-upload.vue'
import { saveTowerDefectReportDate, deleteTowerDefectReportDate } from '@/api/tower-manage'
import { parseTime } from '@/utils'
import common from '@/common'

export default {
  components: {
    fileUpload
  },
  props: {
    list: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      parseTime,
      Delete,
      FileIcon,
      DownloadIcon,
      Delete3,

      code: ''
    }
  },

  computed: {
    isOldTower() {
      return common.oldTowers.includes(this.code)
    }
  },

  created() {
    this.code = this.$route.query.towerCode
  },

  methods: {

    handleAdd(item) {
      if(!item.reportFilePath) return this.$message.warning('Please upload Inspection Report')
      if(!item.defectListName) return this.$message.warning('Please upload Defect list Excel')
      if(!item.towerModeUrl) return this.$message.warning('Please upload 3D Tower Model')
      this.$set(item, 'loading', true)
      this.form = {
        ...item,
        towerCode: this.code
      }
      // console.log('form', this.form)
      this.$message.warning('The server processing time is relatively long, please be patient and wait')
      saveTowerDefectReportDate(this.form).then((res) => {
        this.$set(item, 'loading', false)
        this.$emit('refresh')
      }).catch((error) => {
        this.$set(item, 'loading', false)
      })
    },

    handleDelete(row, index) {
      // 本地的直接删除
      if (!row.towerCode) {
        this.list.splice(index, 1)
        return
      }
      // 删除接口数据
      this.$confirm(`Do you want to delete the data of ${parseTime(row.reportDateStr, 'reg')}?`, 'Tips', {}).then(() => {
        const params = {
          reportDateStr: row.reportDateStr,
          towerCode: row.towerCode,
          time: row.time,
        }
        deleteTowerDefectReportDate(params).then((res) => {
          this.$emit('refresh')
        })
      })
    },

    postReport(row, index) {
      console.log('postReport', row, index)
      this.$set(this.list[index], 'reportFilePath', row.path)
      this.$set(this.list[index], 'reportFileRelativeName', row.fileName)
    },
    delReport(index) {
      this.$set(this.list[index], 'reportFilePath', '')
      this.$set(this.list[index], 'reportFileRelativeName', '')
    },

    postDefectListFile(row, index) {
      this.$set(this.list[index], 'dataDTOList', row.dataList)
      this.$set(this.list[index], 'defectListName', row.fileName)
      this.$set(this.list[index], 'excelUrl', row.excelUrl)
    },
    delDefectListFile(index) {
      this.$set(this.list[index], 'dataDTOList', [])
      this.$set(this.list[index], 'defectListName', '')
      this.$set(this.list[index], 'excelUrl', '')
    },

    postTowerModel(row, index) {
      this.$set(this.list[index], 'towerModeUrl', row.path)
      this.$set(this.list[index], 'towerModeName', row.fileName)
    },
    delTowerModel(index) {
      this.$set(this.list[index], 'towerModeUrl', '')
      this.$set(this.list[index], 'towerModeName', '')
    },

    downloadFile(url, name) {
      const link = document.createElement('a');
      link.href = url;
      link.download = name; // 自定义文件名
      link.target = '_blank';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}
</script>

<style lang="scss" scoped>
.report-data {
  .report-data-item {
    background: #fff;
    margin-bottom: 20px;
    .report-data-item__header {
      padding: 11px 18px;
      display: flex;
      align-items: center;
      .report-data-item__header__title {
        font-size: 16px;
        font-weight: bold;
        margin-right: 24px;
      }
      .report-data-item__header__btn {
        height: 30px;
        margin-left: 15px;
      }
    }
    .report-data-item__upload {
      padding: 24px;
      display: flex;
      .upload-item {
        flex: 1;
        .upload-item__name {
          font-size: 14px;
          color: #4A4A4A;
          margin-bottom: 8px;
        }
        .upload-item__success {
          display: flex;
          align-items: center;
          .file-box {
            width: 400px;
            padding: 7px 12px;
            background: #F7F8FA;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .file-left {
              display: flex;
              align-items: center;
              .file-icon {
                flex-shrink: 0;
                width: 16px;
                height: 16px;
                margin-right: 12px;
              }
              .file-name {
                font-size: 14px;
              }
            }
            .file-download {
              cursor: pointer;
            }
          }
          .file-delete {
            width: 12px;
            height: 12px;
            margin-left: 12px;
            cursor: pointer;
          }
        }
      }
    }
  }
}
</style>